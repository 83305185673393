import REQ from '@/app/REQ'
import editDocTpltDlg from "@/dialg/editDocTpltDlg.vue";
import {downloadBlob} from "@/utils/browserUtils";
import ADMREQ from "@/app/ADMREQ";

export default {
    name: 'docTplts',
    components:{editDocTpltDlg,},

    data() {
        return {
            docTplts:[],
            docTplt:null,
            orgs:[],
            orgId:'',
            order:true,
        }
    },

    computed:{
        displayDocTplts(){
            let {order, docTplts} = this
            if(order) {
                docTplts.sort((tplt1, tplt2) => tplt1.name.toLowerCase().localeCompare(tplt2.name.toLowerCase()))
            }else {
                docTplts.sort((tplt1, tplt2) => tplt2.name.toLowerCase().localeCompare(tplt1.name.toLowerCase()))
            }
            return docTplts
        }
    },

    created() {
        this.getOrgs()
    },

    methods: {
        getOrgs() {
            this.req2Data(ADMREQ.orgs,null ,null)
        },

        getDocTplts(orgId) {
            this.docTplts = []
            this.req2Data(ADMREQ.docTplts, {orgId},'docTplts')
        },

        editDocTplt(docTplt){
            this.docTplt = docTplt
            this.showDlg('editDocTpltDlg')
        },

    },

}
