import formDlgMix from "@/gDlgs/formDlgMix";
import ADMREQ from "@/app/ADMREQ";
import {PRODOUCT} from "@/consts";

export default {
	name: "editOrgDlg",
	mixins: [formDlgMix],
	props:['oldOrg',],

	data() {
		return {
			sname: '',
			name: '',
			type: '',
			code: '',
			products: [],
		}
	},

	watch: {
		working(){
			let {oldOrg} = this
			if (oldOrg) {
				let {sname, name, type, products} = oldOrg
				if(!products) products = []
				Object.assign(this, {sname, name, type, products,})
			}else {
				Object.assign(this, {sname:'', name:'', type:'', code:'', products: []})
			}

		}
	},

	methods: {

		ok() {
			let {sname, name, type, code, products, oldOrg} = this
			if(oldOrg){
				let org = {id:oldOrg.id}
				if(sname != oldOrg.sname) org.sname = sname
				if(name != oldOrg.name) org.name = name
				if(type != oldOrg.type) org.type = type
				if(products != oldOrg.products) org.products = products
				this.reqThenBk(ADMREQ.upOrg, org)
			}else {
				if(this.chk2msg(sname, '请填写简称', name, '请填写全称', type || type == 0, '请选择公司类型',code, '请填写代号',)) return
				let reg = new RegExp("^([a-zA-Z][a-zA-Z0-9]{3,31})$");
				if(!reg.test(code)){
					this.$message({
						message: '代号必须由字母打头且仅能输入数字，位数不得少于四位',
						type: 'warning'
					});
					return
				}
				this.reqThenBk(ADMREQ.addOrg, {sname, name, type, code, products})
			}
		},


	}
};
