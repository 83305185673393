import ADMREQ from "@/app/ADMREQ";

export default {
	name: "commonRes",


	data() {
		return {
			org:{},
			orgs:[],
			orgId:'',
			config:{},
			switchOrg:false,
		};
	},

	created() {
		this.getCommonResCfg()
		this.getOrgs()
	},

	methods: {

		getCommonResCfg() {
			this.req2Data(ADMREQ.commonResCfg,null ,null, ({org})=>{
				if(org) this.orgId = org.id
			})
		},

		getOrgs() {
			this.req2Data(ADMREQ.orgs,null ,null)
		},

		onOrgSeled(){
			let {orgId} = this
			this.req2Data(ADMREQ.setCommonResOrg, {orgId}, null,()=> {
				this.getCommonResCfg()
				this.switchOrg = false
			})
		},

	}
}
;
