import ADMREQ from "@/app/ADMREQ";
import {upWFoundById} from "@/utils/xyhUtils";
import editOrgDlg from "@/dialg/editOrgDlg.vue";
import editOrgAdminDlg from "@/dialg/editOrgAdminDlg.vue";

export default {
	name: "orgs",
	components:{editOrgDlg, editOrgAdminDlg},


	data() {
		return {
			orgs:[],
			orgId:'',
			org:{},
		};
	},

	created() {
		this.getOrgs()
	},

	methods: {
		getOrgs() {
			this.req2Data(ADMREQ.orgs,null ,null)
		},

		addOrgAdmin(id){
			this.orgId = id
			this.showDlg('editOrgAdminDlg')
		},

		eidtOrg(org){
			this.org = org
			this.showDlg('editOrgDlg')
		},

		getOrgAdmin(idx){
			let org = this.orgs[idx]
			this.req2Data(ADMREQ.orgAdmins, {orgId:org.id}, {}, orgAdmins=>{
				if(orgAdmins.length<=0){
					this.$message("该组织尚无管理员")
					return
				}
				org.orgAdmin = orgAdmins.map(it=>it.name).join('、')
				this.$set(this.orgs, idx, org)
			})
		},

	}
}
;
