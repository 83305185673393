
function downloadBlob(blob, fileName) {
	let filename = fileName || "untitled";
	if (typeof window.navigator.msSaveBlob !== "undefined") {
		window.navigator.msSaveBlob(blob, filename);
	} else {
		let blobURL = window.URL.createObjectURL(blob);
		let tempLink = document.createElement("a");
		tempLink.style.display = "none";
		tempLink.href = blobURL;
		tempLink.setAttribute("download", filename);
		if (typeof tempLink.download === "undefined")
			tempLink.setAttribute("target", "_blank");
		document.body.appendChild(tempLink);
		tempLink.click();
		document.body.removeChild(tempLink);
		window.URL.revokeObjectURL(blobURL);
	}
}

function fileNameInHdr(headers){
	const contentDisposition = headers['content-disposition'];
	if (contentDisposition) {
		let strs = contentDisposition.split('=')
		if(strs.length > 1) return window.decodeURI(strs[1])
	}
}

export {
	downloadBlob,
	fileNameInHdr,
}