import formDlgMix from "@/gDlgs/formDlgMix";
import ADMREQ from "@/app/ADMREQ";

export default {
	name: "approvalOrgRegDlg",
	mixins: [formDlgMix],
	props:['id', 'accept'],

	data() {
		return {
			code:'',
			remark: '',
		}
	},

	watch: {
		working(){
			Object.assign(this, {code:'', remark:'',})
		}
	},

	methods: {

		ok() {
			let {accept, id, remark, code} = this
			if(accept){
				let reg = new RegExp("^([a-zA-Z][a-zA-Z0-9]{3,31})$");
				if(!reg.test(code)){
					this.$message({
						message: '代号必须由字母打头且仅能输入数字，位数不得少于四位',
						type: 'warning'
					});
					return
				}
				this.reqThenBk(ADMREQ.acceptOrgReg, {id, remark, code})
			}else {
				this.reqThenBk(ADMREQ.rejectOrgReg, {id, remark})
			}
		},


	}
};
