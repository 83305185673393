
import Vue from "vue";

export default {

	install(Vue) {
		//成功消息提醒
		Vue.prototype.ok = function (msg) { this.$message({ message: msg, type: 'success' }) }

		//确认消息提醒
		Vue.prototype.confirm = function (msg,okCall,confirmButtonText,cancelButtonText){
			this.$confirm(msg, '提示', {
				confirmButtonText: confirmButtonText || '确定',
				cancelButtonText: cancelButtonText || '取消',
				type: 'warning'
			}).then(okCall).catch(() => {
			});
		}

		Vue.prototype.warn = function (msg) { this.$message({ message: msg, type: 'warning' }) }
	},
};
