import ADMREQ from "@/app/ADMREQ";
import {upWFoundById} from "@/utils/xyhUtils";
import approvalOrgRegDlg from "@/dialg/approvalOrgRegDlg.vue";

export default {
	name: "orgRegs",
	components:{approvalOrgRegDlg,},


	data() {
		return {
			orgRegs:[],
			orgId:'',
			accept:'',
		};
	},

	created() {
		this.getOrgRegs()
	},

	methods: {
		getOrgRegs() {
			this.req2Data(ADMREQ.orgRegs, null, null,)
		},

		approvalOrgReg(id, accept) {
			this.orgId = id
			this.accept = accept
			this.showDlg('approvalOrgRegDlg')
		},

	}
}
;
