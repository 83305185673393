import top from '@/components/header.vue'


export default {
	name: 'personnalCon',
	components: { top,},

	data() {
		return {
		}
	},

	created() {
	},

	methods: {

	}
}

