import ADMREQ from "@/app/ADMREQ";
import {upWFoundById} from "@/utils/xyhUtils";
import addAdminDlg from "@/dialg/addAdminDlg.vue";

export default {
	name: "admins",
	components:{addAdminDlg,},


	data() {
		return {
			admins: [],
		};
	},

	created() {
		this.getAdmins()
	},

	methods: {
		getAdmins() {
			this.req2Data(ADMREQ.admins, null, {admins: 'admins'}, ({admins, usrs}) => {
				upWFoundById(admins, usrs, 'usrId', (admin, usr) => {
					admin.name = usr.name
					admin.mobile = usr.mobile
					admin.email = usr.email
				})
			})
		},

		rmAdmin(id){
			this.req2Data(ADMREQ.rmAdmin, {id},null,()=>this.getAdmins())
		}
	}
}
;
