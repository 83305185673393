import ADMREQ from "@/app/ADMREQ";
import Options from "@/Options";
import {DOC_STATE, DOC_TYPE, DOC_FEE_STATE, } from "@/consts";
import docFeeStateDlg from "@/admApp/docFeeStateDlg.vue";
import {invMapOf} from "@/utils/xyhUtils";

const {DocTypesSMap, DocStatesSMap, DocFeeStatesSMap} = Options

export default {
	name: "finance",
	components:{docFeeStateDlg,},

	data() {
		return {
			orgs: [],
			orgId: '',
			prjtId: '',
			prjts: [],
			docs: [],
			doc: null,
			feeState:null,
			docId:null,
			typeN: null,
			type: null,
			checks: {},
			isBatch: false,
			filterWord1: '',
			filterWord2: '',
			order: {
				num: true,
			},
			sortingProperty: '',
			docStatus: DOC_STATE.fixed,
			docFeeState: 'unReceive',
			docType: 'unAnnex',
		};
	},

	computed: {

		displayDocs(){
			let {filterWord2, docStatus, docFeeState, docType, order, docs} = this
			if(filterWord2.length>0) docs = this.docs.filter(it=>
				it.name.toLowerCase().includes(filterWord2) ||
				it.num.toLowerCase().includes(filterWord2) ||
				it.feeOptorN?.toLowerCase().includes(filterWord2)
			)
			if(docStatus) docs = docs.filter(it=>it.state == docStatus)
			if(docFeeState) docs = docs.filter(it => docFeeState == 'unReceive' ? it.feeState != DOC_FEE_STATE.received:it.feeState == docFeeState)
			if(docType) docs = docs.filter(it => docType == 'unAnnex' ? it.type != DOC_TYPE.annex:it.type == docType)
			for(let i in order){
				if(order[i]) {
					docs.sort((doc1, doc2) => doc1[i].toLowerCase().localeCompare(doc2[i].toLowerCase()))
				}else {
					docs.sort((doc1, doc2) => doc2[i].toLowerCase().localeCompare(doc1[i].toLowerCase()))
				}
			}
			return docs
		},

		docCensus(){
			let {docs} = this
			let docCensus = {sop: {}, policy: {}, manual: {}, annex: {}, file: {}, all:{},}
			let docState = invMapOf(DOC_FEE_STATE)
			for (let i in docCensus) {
				docCensus[i].total = 0
				for (let j in DOC_FEE_STATE) {
					docCensus[i][j] = 0
				}
			}
			docCensus.all.total = docs.length
			docs.forEach(doc => {
				docCensus[doc.type].total++
				docCensus[doc.type][docState[doc.feeState]]++
				docCensus.all[docState[doc.feeState]]++
			})
			return docCensus
		},

		allSeled() {return this.displayDocs.every(it=>this.checks[it.id]);},

		isIndeterminate() { return  !(this.allSeled || this.displayDocs.every(it=>!this.checks[it.id]))},
	},

	created() {
		this.getOrgs()
	},

	methods: {
		getOrgs() {
			this.req2Data(ADMREQ.orgs, null, null)
		},

		getDocPrjtsOfOrg(orgId) {
			this.req2Data(ADMREQ.docPrjtsOfOrg, {orgId}, 'prjts', () => {
				this.prjtId = ''
			})
		},

		getDocsOfOrg(prjtId) {
			let {orgId} = this
			this.req2Data(ADMREQ.docsOfOrg, {orgId, prjtId}, {docs:'docs'}, ({docs}) => {
				docs.forEach(it=> {
					if(!it.type) it.type = DOC_TYPE.sop
					if(!it.state) it.state = DOC_STATE.remarking
					if(!it.feeState) it.feeState = DOC_FEE_STATE.todo
				})
			})
		},

		editDocFee(feeState, docId, doc){
			Object.assign(this, {docId, doc,feeState})
			this.showDlg('docFeeStateDlg')
		},

		editDocFeeState(docFee){
			let {docId, feeState, orgId, prjtId, checks, displayDocs} = this
			docFee.docIds = []
			if(docId) {
				docFee.docIds.push(docId)
			}else {
				displayDocs.forEach(it=> {
					if (checks[it.id]) docFee.docIds.push(it.id)
				})
			}
			docFee.feeState = feeState
			docFee.orgId = orgId
			if(displayDocs.find(doc => checks[doc.id] && doc.feeState == DOC_FEE_STATE.received)){
				this.confirm('选中文档中存在已收款文档，是否继续？',()=>{
					this.req2Data(ADMREQ.regDocFeeState, docFee, {}, ()=>this.getDocsOfOrg(prjtId))
				})
			}else {
				this.req2Data(ADMREQ.regDocFeeState, docFee, {}, ()=>this.getDocsOfOrg(prjtId))
			}
			this.doc = null
			docId = null
		},

		search() {
			this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
		},

		transferDoc(){
			let docIds=[]
			let {checks, displayDocs} = this
			displayDocs.forEach(it=> {
				if (checks[it.id]) docIds.push(it.id)
			})
			if(this.chk2msg(docIds.length>0, '请选择要转移的文档')) return
			this.isBatch=false
		},

		sorted(propertyN){
			this.sortingProperty = propertyN
			let order = !this.order[propertyN]
			delete this.order[propertyN]
			this.$set(this.order,propertyN,order)
		},

		docFeeStateFilter(state){
			this.$set(this,'docFeeState',state)
		},

		statusFilter(status){
			this.$set(this,'docStatus',status)
		},

		typeFilter(type){
			this.$set(this,'docType',type)
		},

		batchSelection(){
			let {allSeled, isIndeterminate, displayDocs} = this
			let s = !(isIndeterminate || allSeled)
			displayDocs.forEach(it => this.$set(this.checks, it.id, s))
		},

		docsExport(typeN, stateN, feeStateN) {
			console.log(feeStateN)
			const {export_json_to_excel} = require('@/vendor/Export2Excel');
			const tHeader = ['文档编号', '中文名称', '费用状态(' + feeStateN + ')', '类型(' + typeN + ')', '状态(' + stateN + ')','版本号'];
			const filterVal = ['num', 'name', 'feeState', 'type', 'state','ver'];
			const data = this.displayDocs.map(doc=> {
				let {...doc2} = doc
				doc2.type = this.DocTypesSMap[doc.type]
				doc2.state = this.DocStatesSMap[doc.state]
				doc2.feeState = this.DocFeeStatesSMap[doc.feeState]
				return doc2
			}).map(v => filterVal.map(j => v[j]))
			export_json_to_excel(tHeader, data, `文档收费情况`);
		},
	}
}
;
