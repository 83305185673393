import {PLATEFORM} from '@/consts'
import personalCon from './personal.vue'
import attendOrg from './attendOrg.vue'
import personalPage from './personalPage.vue'

export default {
	path: '/personal',  component: personalCon,
	children: [
		{path: 'personalPage', component: personalPage},
		{path: 'attendOrg', component: attendOrg}
	],
}