import Vue from 'vue'
import Router from 'vue-router'
import HomeRouter from '../views/home/router'
import primaryRts from '../views/primaryRts'
import RegistRouter from '../views/regist/router'
import FindPwdRouter from '../views/findPwd/router'
import CoursewareRouter from '../views/courseware/router'
import deving from "@/coms/deving.vue";
import com404 from "@/coms/404.vue";
import admRts from "@/admApp/admRts";
import personalRts from "@/personal/personalRts"

Vue.use(Router)

function pgmRt(p) {
    if(!p.startsWith("/"))  p='/'+p
    return {path:p, redirect: '/main' + p}
}

export default new Router({
	routes: [
		{
			path: '/', name: 'login', meta: { title: 'login' }, alias: '/login',
			component: resolve => require(['../components/login.vue'], resolve),
            props: r => r.query,
		},

        { path: '/reg', redirect: '/reg/user' },
        { path: '/courseware', redirect: '/courseware/index' },
        { path: '/findPwd', redirect: '/findPwd/1' },

		HomeRouter,
		RegistRouter,
		CoursewareRouter,
		FindPwdRouter,
        primaryRts,
        admRts,
        personalRts,

        pgmRt('myPrjts'),
        pgmRt('myDocPrjts'),
        pgmRt('myOrg'),

        pgmRt('statistics'),
        pgmRt('craOrgStatistics'),
        pgmRt('craManagement'),
        pgmRt('workingTime/:id'),
        pgmRt('inspectParticulars/:id'),
        pgmRt('projectTabel'),
        pgmRt('CraDetails/:id'),
        pgmRt('CraBidding'),
        pgmRt('CramouthStatistics'),
        pgmRt('statistics1'),
        pgmRt('statistics2'),
        pgmRt('statistics3'),
        pgmRt('orCrcParticulars/:id'),
        pgmRt('officeList'),
        pgmRt('crcWorkList'),
        pgmRt('projectWorkList'),
        pgmRt('officeProject'),
        pgmRt('crcProject'),
        pgmRt('mouthStatistics'),
        pgmRt('smoStatistics'),
        pgmRt('somProjectParticulars/:id'),
        pgmRt('organizationList'),
        pgmRt('projectList'),
        pgmRt('platformStatistics'),
        pgmRt('plProjectParticulars/:id'),
        pgmRt('plCrcParticulars/:id'),
        pgmRt('smoPersonnel'),
        pgmRt('organizationPersonnel'),
        pgmRt('organizationProject'),
        pgmRt('crcManagement'),
        pgmRt('crcParticulars/:id'),
        pgmRt('projectManage'),
        pgmRt('interviewParticulars/:id'),
        pgmRt('subjects/:patientId/:projectId'),
        pgmRt('projectParticulars/:id'),
        pgmRt('sickList/:id'),
        pgmRt('sickParticulars/:id/:patientId'),
        pgmRt('addProject/:id/:isEidt'),
        pgmRt('reportQuery'),
        pgmRt('crcTest'),
        pgmRt('assess'),
        pgmRt('detail/:id'),
        pgmRt('dailyProgress'),
        pgmRt('changePassword'),
        pgmRt('CraMechanismList'),
        pgmRt('CraDepartment/:id'),
        pgmRt('BidCompanyList'),
        pgmRt('ProjectList/:id'),
        pgmRt('CrcManageList/:id'),
        pgmRt('CrcSelectList/:id'),
        pgmRt('CrcMyList/:id'),
        pgmRt('profiles'),
        pgmRt('profile/:id'),
        pgmRt('editProfile/:id'),
        pgmRt('editsop/'),
        pgmRt('departments'),

		{
			path: '/login2', name: 'login2', meta: { title: 'login2' },
			component: resolve => require(['../components/login2.vue'], resolve),
		},

        { path: '*', component:com404 }
	],
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
})
