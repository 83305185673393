import formDlgMix from "@/gDlgs/formDlgMix";
import {mapState} from "vuex";

export default {
    name: "docFeeStateDlg",
    mixins: [formDlgMix],
    props: ['oldFeeState'],

    data() {
        return {
            feeOptorN:'',
            feeOpDate:'',
        }
    },

    computed:{
        ...mapState({
            userN: ({myself}) => myself?.name ,
        }),
    },

    watch: {
        working(){
            let {oldFeeState, userN} = this
            if(oldFeeState){
                Object.assign(this, {feeOptorN:oldFeeState.feeOptorN, feeOpDate:oldFeeState.feeOpDate})
            }else {
                Object.assign(this, {feeOptorN:userN, feeOpDate:'',})
            }
        }
    },

    methods: {
        onOk(){
            let {feeOptorN, feeOpDate} =this
            if(this.chk2msg(feeOptorN, '请输入经办人', feeOpDate, '请选择请款日期')) return
            this.$emit('oked',{orgId: '', feeOptorN, feeOpDate, feeState:''})
            this.working =false
        },
    }
};
