import formDlgMix from "@/gDlgs/formDlgMix";
import ADMREQ from "@/app/ADMREQ";

export default {
	name: "editOrgAdminDlg",
	mixins: [formDlgMix],
	props:['orgId'],

	data() {
		return {
			name: '',
			mobile:'',
		}
	},

	watch: {
		working(){
			Object.assign(this, {name:'', mobile:'',})
		}
	},

	methods: {

		ok() {
			let {orgId, name, mobile} = this
			if(this.chk2msg(name, '请填写姓名', mobile, '填写手机号',)) return
			this.reqThenBk(ADMREQ.addOrgAdmin, {orgId, name, mobile})
		},


	}
};
