import {PLATEFORM} from '@/consts'
import admCon from './adm.vue'
import orgs from '@/admApp/orgs.vue'
import orgRegs from "@/admApp/orgRegs.vue";
import admins from "@/admApp/admins.vue";
import cloneDocs from "@/admApp/cloneDocs.vue";
import docTplts from "@/admApp/docTplts.vue";
import commonRes from "@/admApp/commonRes.vue"
import finance from "@/admApp/finance.vue";


export default {
	path: '/adm',  component: admCon,
	children: [

		{path: 'orgs', component:orgs},
		{path: 'orgRegs', component:orgRegs},
		{path: 'admins', component:admins},
		{path: 'cloneDocs', component:cloneDocs},
		{path: 'docTplts', component:docTplts},
		{path: 'commonRes', component:commonRes},
		{path: 'finance', component:finance},

	],
}