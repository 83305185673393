import top from '@/components/header.vue'
import ADMREQ from "@/app/ADMREQ";
import {mapGetters} from "vuex";

let Navs = [
	{n: '组织管理', p: 'orgs'},
	{n: '组织注册', p: 'orgRegs'},
	{n: '平台管理员', p: 'admins'},
	{n: '克隆文档', p: 'cloneDocs'},
	{n: '文档模板', p: 'docTplts'},
	{n: '平台设置', p: 'commonRes'},
	{n: '收费管理', p: 'finance'}
]

export default {
	name: 'admCon',
	components: { top,},

	data() {
		return {
			Navs,
		}
	},

	computed: {
		...mapGetters(['amIPlatAdm']),
	},

	created() {
	},

	methods: {
		handleClick(index) {
			this.active = index
			this.$router.push(this.menus[index].p)
		}
	}
}

