import ADMREQ from "@/app/ADMREQ";
import {genMap,upWFoundById} from '@/utils/xyhUtils'

export default {
	name: "cloneDocs",


	data() {
		return {
			fromAnnexes:[],
			toAnnexes:[],
			orgs:[],
			fromOId:'',
			fromPrjtId:'',
			toOId:'',
			toPrjtId:'',
			org:{},
			fromPrjts:[],
			toPrjts:[],
			fromDocs:[],
			toDocs:[],
			replaces:[{from:'',to:''}],
			checkDoc: {},
			checkAnnex:{},
			selectAll:false,
			isIndeterminate: false
		};
	},

	created() {
		this.getOrgs()
	},

	methods: {
		getOrgs() {
			this.req2Data(ADMREQ.orgs,null ,null)
		},

		getDocPrjtsOfOrg(orgId,type){
			this.req2Data(ADMREQ.docPrjtsOfOrg, {orgId}, type ? 'fromPrjts':'toPrjts',()=> {
				if(type){
					this.fromOId = orgId
					this.fromPrjtId = ''
				}else {
					this.toOId = orgId
					this.toPrjtId = ''
				}
			})
		},

		getDocsOfOrg(prjtId,type){
			let orgId = type ? this.fromOId:this.toOId
			this.req2Data(ADMREQ.docsOfOrg, {orgId,prjtId}, {docs:type ? 'fromDocs' : 'toDocs'},()=> {
				let {fromDocs,fromAnnexes,toDocs,toAnnexes} = this
				if (type) {
					this.checkDoc = {}
					this.checkAnnex = {}
					fromDocs.forEach(doc=> {
						doc.Num = doc.num
						doc.Name = doc.name
						// doc.annexes = []
						this.checkDoc[doc.id] = false
					})
					// upWFoundById(fromAnnexes,fromDocs,'docId',(annex,doc)=>{
					// 	annex.Num = annex.num
					// 	annex.Name = annex.name
					// 	doc.annexes.push(annex)
					// 	this.checkAnnex[annex.id] = false
					// })
					this.isIndeterminate = false
				}
				// else {
				// 	toDocs.forEach(doc=>doc.annexes = [])
				// 	upWFoundById(toAnnexes,toDocs,'docId',(annex,doc)=>{doc.annexes.push(annex)})
				// }
				type ? this.fromPrjtId:this.toPrjtId = prjtId
			})
		},

		CheckedChange() {
			this.$forceUpdate()
			let {checkDoc,checkAnnex} = this
			let checkedCount = 0;
			for (let i in checkDoc){
				if(checkDoc[i]) checkedCount++
			}
			for (let i in checkAnnex){
				if(checkAnnex[i]) checkedCount++
			}
			this.selectAll = checkedCount === (Object.keys(checkDoc).length + Object.keys(checkAnnex).length);
			this.isIndeterminate = checkedCount > 0 && checkedCount < (Object.keys(checkDoc).length + Object.keys(checkAnnex).length);
		},

		batchSelection(selectAll){
			this.selectAll = selectAll
			this.isIndeterminate = false
			for (let i in this.checkDoc){
				this.checkDoc[i] = selectAll
			}
			// for (let i in this.checkAnnex){
			// 	this.checkAnnex[i] = selectAll
			// }
		},

		insert(arr, idx, obj) {
			arr.splice(idx + 1, 0, obj)
		},

		strikeOut(arr, idx) {
			arr.splice(idx, 1)
		},

		swap(arr, idx) {
			let t = arr[idx]
			arr.splice(idx + 2, 0, t)
			arr.splice(idx, 1)
		},

		clone(){
			let {fromOId,fromPrjtId,toOId,toPrjtId,replaces,fromDocs,toDocs,checkDoc,checkAnnex} = this
			let docMap = genMap(fromDocs)
			let annexMap = genMap(this.fromAnnexes)
			let docs = []
			// let annexes = []
			let replaceWords = []
			for (let i in checkDoc){
				if(checkDoc[i]) docs.push({id: i, name: docMap[i].name, num: docMap[i].num})
			}
			// for (let i in checkAnnex){
			// 	if(checkDoc[annexMap[i].docId] && checkAnnex[i]) annexes.push({id:i,name:annexMap[i].name,num:annexMap[i].num})
			// }
			if(this.chk2msg(fromOId,'请选择起始公司',fromPrjtId,'请选择起始项目',toOId,'请选择目标组织',toPrjtId,'请选择目标项目',docs.length>0,'请选择转移文档')) return
			replaceWords = replaces.filter(replace=>replace.from)
			if(!docs.find((doc,idx) => {
				if (toDocs.find(toDoc => doc.name == toDoc.name || doc.num == toDoc.num)) {
					this.$message('文档' + doc.name + '在目标项目中存在名称或编号的重复')
					return true
				}
			}))
			this.$confirm('确认开始克隆吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.req2Data(ADMREQ.cloneDocs,{replaces:replaceWords,fromOId,toOId,toPrjtId,docs},{},()=>{
					this.getDocsOfOrg(fromPrjtId,' from')
					this.getDocsOfOrg(toPrjtId)
					this.ok('克隆成功')
				})
			}).catch(() => {
			});
		},
	}
}
;
