import REQ from "@/app/REQ";
import {PRODOUCT} from "@/consts";
import {mapState} from "vuex";

export default {
	name: "attendOrg",
	components:{},


	data() {
		return {
			orgs:[],
			staffs:[],
			orgIdx:0,
			switchOrg:false,
		};
	},

	computed:{
		...mapState({orgProducts: ({ curOrg }) => curOrg?.products}),
	},

	created() {
	},

	methods: {
		OnCheckBindOrg(){
			this.req2Data(REQ.ckbindOrgs, null, null, ({orgs, staffs})=>{
				if (staffs.length >1){
					this.switchOrg = true
				} else if(staffs.length == 1) {
					this.setCurOrg(orgs[0], staffs[0])
					if (this.orgProducts.find(it => it == PRODOUCT.sop)) {
						this.$router.push('/main/myDocPrjts')
					} else if (this.orgProducts.find(it => it == PRODOUCT.stone)) {
						this.$router.push('/main/stoneMarkState')
					} else {
						this.$router.push('/personal/personalPage')
					}
				} else {
					this.$message('尚未发现贵单位管理员加你')
				}
			})
		},

		onOrgSeled() {
			let {orgs,orgIdx, staffs} = this
			if(orgIdx >=0) {
				let org = orgs[orgIdx]
				let staff = staffs.find(it=>it.oid == org.id)
				this.setCurOrg(org, staff)
				if (this.orgProducts.find(it => it == PRODOUCT.sop)) {
					this.$router.push('/main/myDocPrjts')
				} else if (this.orgProducts.find(it => it == PRODOUCT.stone)) {
					this.$router.push('/main/stoneMarkState')
				} else {
					this.$router.push('/personal/personalPage')
				}
			}
			this.switchOrg = false
		},
	}
}
;
