import formDlgMix from "@/gDlgs/formDlgMix";
import ADMREQ from "@/app/ADMREQ";

export default {
	name: "addAdminDlg",
	mixins: [formDlgMix],

	data() {
		return {
			content:'',
			usrId:'',
			usrs:[],
			checks:[],
		}
	},

	watch: {
		working(){
			Object.assign(this, {content:'', usrId:'', usrs:[], checks:[],})
		}
	},

	methods: {
		search(){
			let {content} = this
			this.req2Data(ADMREQ.usrsWith,{content},'usrs', (usrs)=>{
				if(usrs.length == 0) this.$message({
					message: '该用户不存在',
					type: 'warning'
				});
			})
		},

		ok() {
			let {checks, usrs, usrId} =this
			if(checks.filter(it=>it).length>1){
				this.$message({
					message: '单次只能添加一名管理员',
					type: 'warning'
				});
				return
			}else {
				checks.forEach((it,idx)=> {if (it) usrId = usrs[idx].id})
				this.reqThenBk(ADMREQ.addAdmin, {usrId})
			}
		},
	}
};
