
import Vue from 'vue'
import App from '../App.vue'
import Cookies from 'js-cookie'
import router from './router'
import axios from 'axios'
Vue.prototype.$http = axios;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import Vuex from 'vuex';
import store from './store';
Vue.use(Vuex)
import animate from 'animate.css';
Vue.use(animate)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-area-linkage/dist/index.css'; // v2 or higher
import VueAreaLinkage from 'vue-area-linkage';
Vue.use(VueAreaLinkage)
Vue.use(ElementUI);
import PubSub from "@/lib/Pub";
import AMap from 'vue-amap'
Vue.use(AMap)
AMap.initAMapApiLoader({
	key: 'db9df9db2cb38f317f1823f92021198c',
	plugin: ['Autocomplete', 'PlaceSearch', 'Scale', 'OverView', 'ToolBar', 'MapType', 'PolyEditor', 'AMap.CircleEditor'],
	// 默认高德 sdk 版本为 1.4.4
	v: '1.4.4'
});

import commonDlgs from "@/gDlgs/gDlg";
Vue.use(commonDlgs)
import ConstsMappor from "@/ConstsMappor";
Vue.use(ConstsMappor)
import {downloadBlob, fileNameInHdr } from "@/utils/browserUtils";
import msgUtils from "@/utils/msgUtils";
Vue.use(msgUtils)

//import Export2Excel from './vuex/Expor2Excel';

// Object.prototype.toJson = function() { return JSON.stringify(this) }
// String.prototype.toObj = function() { return JSON.parse(this) }

Vue.config.productionTip = false
Vue.prototype.axios = axios
//路由钩子 拦截
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
})


axios.defaults.baseURL = XyhConf.HglSHost
axios.defaults.headers.post['Content-Type'] = 'application/json'
Vue.prototype.craUrl = XyhConf.HglSHost

function keep2num(n) { return ((n < 10 && n >=0)? '0':'')+n }
Date.prototype.ymdhmFmt = function(){return [this.getFullYear(), this.getMonth()+1, this.getDate(),this.getHours(),this.getMinutes()].join('-')}
Date.prototype.YMDHMFmt = function(){return this.getFullYear() + '-' + keep2num(this.getMonth()+1) + '-' + keep2num(this.getDate()) + ' ' +  keep2num(this.getHours()) + ':' + keep2num(this.getMinutes())}
Date.prototype.MDHMFmt = function(){return keep2num(this.getMonth()+1)+ '-' + keep2num(this.getDate())+ ' ' + keep2num(this.getHours())+ ':' + keep2num(this.getMinutes())}
Date.prototype.ymdFmt = function(){return [this.getFullYear(), this.getMonth()+1, this.getDate()].join('-')}
Date.prototype.YMDFmt = function(){return [this.getFullYear(), keep2num(this.getMonth()+1), keep2num(this.getDate())].join('-')}
Date.prototype.ymFmt = function(){return [this.getFullYear(), this.getMonth()+1].join('-')}
Date.prototype.YMFmt = function(){return [this.getFullYear(), keep2num(this.getMonth()+1)].join('-')}
Date.prototype.mdFmt = function(){return [this.getMonth()+1, this.getDate()].join('-')}
Date.prototype.MDFmt = function(){return [keep2num(this.getMonth()+1), keep2num(this.getDate())].join('-')}
Date.prototype.SFmt = function(){return [(this.getYear() < 2000 ? this.getYear() + 1900 : this.getYear()).toString().substr(2, 2), keep2num(this.getMonth()+1), keep2num(this.getDate())].join('')}
Date.prototype.HMFmt = function(){return keep2num(this.getHours())+ ':' + keep2num(this.getMinutes())}

Date.prototype.weekFirstDay = function () {
	let date = new Date(this)
	date.setDate(this.getDate() - this.getDay());
	return date
}

Vue.prototype.convertToChinaNum = function (num) {
	let arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
	let arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿');//可继续追加更高位转换值
	if (!num || isNaN(num)) {
		return "零";
	}
	let english = num.toString().split("")
	let result = "";
	for (let i = 0; i < english.length; i++) {
		let des_i = english.length - 1 - i;//倒序排列设值
		result = arr2[i] + result;
		let arr1_index = english[des_i];
		result = arr1[arr1_index] + result;
	}
	//将【零千、零百】换成【零】 【十零】换成【十】
	result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
	//合并中间多个零为一个零
	result = result.replace(/零+/g, '零');
	//将【零亿】换成【亿】【零万】换成【万】
	result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
	//将【亿万】换成【亿】
	result = result.replace(/亿万/g, '亿');
	//移除末尾的零
	result = result.replace(/零+$/, '')
	//将【零一十】换成【零十】
	//result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
	//将【一十】换成【十】
	result = result.replace(/^一十/g, '十');
	return result;
},

Vue.prototype.setCookie = function (name, value, days) {
	Cookies.set(name, value, { expires: days })
}
//取cookies函数
Vue.prototype.getCookie = function (name) {
	return Cookies.get(name)
}
//删除cookie
Vue.prototype.delCookie = function (name) { Cookies.set(name, "") };

Vue.prototype.warn = function (msg) { this.$message({ message: msg, type: 'warning' }) }

Vue.prototype.setData = function (dat) { Object.assign(this, dat)  }

Vue.prototype.succeed = Vue.prototype.ok
Vue.prototype.defeated = Vue.prototype.warn

if (typeof(FileReader.prototype.readAsBinaryString) !== "function") {
	FileReader.prototype.readAsBinaryString = function (fileData) {
		let binary = "";
		let pt = this;
		let reader = new FileReader();
		reader.onload = function (e) {
			let bytes = new Uint8Array(reader.result);
			let length = bytes.byteLength;
			for (let i = 0; i < length; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			let obj = {};
			obj.target = {result: binary};
			pt.onload(obj);
		}
		reader.readAsArrayBuffer(fileData);
	}
}

let scripts = document.getElementsByTagName("script")
let indexJs = ''
for(let i = 0; i< scripts.length; ++i){
	let path = scripts[i].src.replace(/^.*?\:\/\/[^\/]+/, "")
	if(path.startsWith('/js/index.')) {
		indexJs = path
		break
	}
}
let ReqVer = indexJs.substr(10).substr(0, indexJs.substr(10).indexOf('.')) || 'dev2'

Vue.prototype.downloadFile = function (name,url){
	if((typeof fetch) == "undefined"){
		let a = document.createElement("a");    //创建a标签
		a.download = '';  //设置被下载的超链接目标（文件名）
		a.href = url;                            //设置a标签的链接
		document.body.appendChild(a);            //a标签添加到页面
		a.click();                                //设置a标签触发单击事件
		document.body.removeChild(a);            //移除a标签
	}else {
		fetch(url).then(res => res.blob()).then(blob => {// 将链接地址字符内容转变成blob地址
			downloadBlob(blob,name)
		})
	}
}

Vue.prototype.downloadR = function (req, data, config) {
	config.responseType = 'blob'
	axios.post(req, data, config).then(r => {
		downloadBlob(r.data, fileNameInHdr(r.headers))
	})
}

Vue.prototype.download = function (req, dataOrId) {
	let data = dataOrId == null ? {} : (typeof (dataOrId) == 'object' ? dataOrId : { id: dataOrId })
	let {token, curStaff,curOrg} = this.$store.state
	let headers = { token, staff: curStaff?.id, orgId:curOrg?.id, V:ReqVer }
	return this.downloadR(req, data, {headers})
}

Vue.prototype.postReqR = function (req, data, okCall, failCall, completCall, config, okMsg) {
	if (typeof (failCall) == 'undefined')
		failCall = err => {
			var info = err.msg
			if (err.detail) info = info + (info ? ' : ' : '') + err.detail
			this.warn(info || '未知错误！！')
		}

	axios.post(req, data, config).then(r => {
		if (r.data.code != 0 && r.data.code != 200)
			return failCall ? failCall(r.data) : null

		if (okCall) okCall(r.data.data)
		if (okMsg == null) return
		let msg = typeof (okMsg) == 'function' ? okMsg(r.data) : (okMsg || r.data.msg || r.data.detail)
		if (msg) this.ok(msg)
	}).catch(err => {
		console.log(err)
		if (failCall) failCall({ code: -1, msg: '网络或服务器异常!' })
	}).finally(completCall)
}

Vue.prototype.postReq = function (reqp, data, okCall, failCall, completCall, config) {
    this.postReqR(reqp.req, data, okCall, failCall, completCall, config, reqp.okmsg)
}

Vue.prototype.authedReqR = function (req, dataOrId, okCall, failCall, completCall, okMsg) {
	let data = dataOrId == null ? {} : (typeof (dataOrId) == 'object' ? dataOrId : { id: dataOrId })
	let {token, curStaff, curOrg} = this.$store.state //this.$root
	let headers = { token, staff: curStaff?.id, orgId:curOrg?.id, V:ReqVer }
	this.postReqR(req, data, okCall, failCall, completCall, { headers }, okMsg)
}

Vue.prototype.authedReq = function (reqp, dataOrId, okCall, failCall, completCall) {
    this.authedReqR(reqp.req, dataOrId, okCall, failCall, completCall, reqp.okmsg)
}

Vue.prototype.req2Data = function (req, dataOrId, mapDef, okCall) {
	this.authedReqR(req, dataOrId, rdat=>{
		let d= {}
		if(!mapDef) d = rdat
		else if(typeof(mapDef) == 'string')  d[mapDef] = rdat
		else for(let i in mapDef) { d[mapDef[i]] = rdat[i]}
		Object.assign(this,d)
		okCall && okCall(rdat)
	})
}

Vue.prototype.loadUserInfo = function () {
	this.$store.commit('loadUserInfo')
}

Vue.prototype.setCurUser = function (user, token, memOnly ) {
	this.$store.commit('setCurUser', {user, token, memOnly})
}

Vue.prototype.setCurOrg = function (org, staff, memOnly) {
	this.$store.commit('setCurOrg', {org, staff, memOnly})
}


Vue.prototype.goto = function (path, propV) {
	let p = path.startsWith('/') ? path : `/main/${path}`
	this.$router.push((typeof propV == 'undefined' )? p : (p + '/' + propV))
}

Vue.prototype.chk2msg = function (...conditionAndMsgs) {
	for(let i = 0; i < conditionAndMsgs.length; i += 2) {
		if (conditionAndMsgs[i]) continue
		if (conditionAndMsgs[i + 1]) this.warn(conditionAndMsgs[i + 1])
		return true
	}
	return false
}

var app = new Vue({
	data: { userInfo:null, curStaff:null, adminInfo: { name: ''} },
	router,
	axios,
	store,
	render: h => h(App)
}).$mount('#app')



