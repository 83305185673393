
import PubSub from "@/lib/Pub";

export default {

	install(Vue, options) {

		Vue.prototype.showDlg = function(dlg) { this.$refs[dlg].working = true }

		Vue.prototype.editFile = function (val, title, okFun) {
			PubSub.emit("editFile", val, title, okFun)
		}

		Vue.prototype.editStr = function (val, title, okFun) {
			PubSub.emit("editStr", val, title, okFun)
		}

		Vue.prototype.editText = function (val, title, okFun) {
			PubSub.emit("editText", val, title, okFun)
		}

		Vue.prototype.editDate = function (val, title, okFun) {
			PubSub.emit("editDate", val, title, okFun)
		}
		Vue.prototype.editStrs = function (items, title, obj, okFun, kN,) {
			PubSub.emit("editStrs", items, kN,  title, obj, okFun)
		}

		Vue.prototype.selMul = function (items, title, okFun, kN) {
			PubSub.emit("selMul", items, kN, title, okFun)
		}

		Vue.prototype.selSgl = function (items, title, okFun, valKN, labelKN) {
			PubSub.emit("selSgl", items, valKN, labelKN, title, okFun)
		}

		Vue.prototype.initCommonDlgs = function () {
			PubSub.subscribe("editFile", (file, title, okFun) => {
				let gDlg = this.$refs['editFileDlg']
				gDlg && gDlg.show(title, okFun, file)
			});

			PubSub.subscribe("editStr", (content, title, okFun) => {
				let gDlg = this.$refs['editStrDlg']
				gDlg && gDlg.show(title, okFun, content)
			});

			PubSub.subscribe("editText", (content, title, okFun) => {
				let gDlg = this.$refs['editTextDlg']
				gDlg && gDlg.show(title, okFun, content)
			});

			PubSub.subscribe("editDate", (content, title, okFun) => {
				let gDlg = this.$refs['editDateDlg']
				gDlg && gDlg.show(title, okFun, content)
			})

			PubSub.subscribe("editStrs", (items, kN, title, okFun) => {
				let gDlg = this.$refs['editStrsDlg']
				gDlg && gDlg.show(title, okFun, items, kN)
			});

			PubSub.subscribe("selMul", (items, kN, title, okFun) => {
				let gDlg = this.$refs['selMulDlg']
				gDlg && gDlg.show(title, okFun, items, kN)
			});

			PubSub.subscribe("selSgl", (items, valKN, labelKN, title, okFun) => {
				let gDlg = this.$refs['selSglDlg']
				gDlg && gDlg.show(title, okFun, items, valKN, labelKN)
			});
		}
	},
};
