import * as consts from '@/consts.js'
import Options from "@/Options"
const {
	PrjtStagesSMap,
	CrRolesSMap,
	PrjtOrgRolesSMap,
	OrgTypesSMap,
	DeptTypesSMap,
	SiteStatesSMap,
	MonTypesSMap,
	MonDayStatesSMap,
	MonVeriStatesSMap,
	MonTopic_ClassesSMap,
	MonTopic_AnswersSMap,
	MvrStatesSMap,
	StoneCrucialsSMap,
	StoneLvsSMap,
	StoneRoleTypesSMap,
	StoneTypesSMap,
	StoneSuitStatesSMap,
	SiteStagesSMap,
	SiteStoneStagesSMap,
	prjtStagesSMap,
	PrjtStoneStagesSMap,
	LawStatesSMap,
	LawTypesSMap,
	OrgRegStatesSMap,
	ProdouctsSMap,
	DocTypesSMap,
	DocRolesSMap,
	RemarkStatesSMap,
	DocStatesSMap,
	DocFeeStatesSMap,
	DocFileWaysSMap,
} = Options

export default {
	install(Vue) {
		Vue.filter('PrjtStage', v=>PrjtStagesSMap[v] || v)
		Vue.filter('CrRole', v=>CrRolesSMap[v] || v)
		Vue.filter('PrjtOrgRole', v=>PrjtOrgRolesSMap[v] || v)
		Vue.filter('OrgType', v=>OrgTypesSMap[v] || v)
		Vue.filter('DeptType', v=>DeptTypesSMap[v] || v)
		Vue.filter('SiteState', v=>SiteStatesSMap[v] || v)
		Vue.filter('MonType', v=>MonTypesSMap[v] || v)
		Vue.filter('MonDayState', v=>MonDayStatesSMap[v] || v)
		Vue.filter('MonVeriState', v=>MonVeriStatesSMap[v] || v)
		Vue.filter('MonTopic_Classe', v=>MonTopic_ClassesSMap[v] || v)
		Vue.filter('MonTopic_Answer', v=>MonTopic_AnswersSMap[v] || v)
		Vue.filter('MvrState', v=>MvrStatesSMap[v] || v)
		Vue.filter('StoneCrucial', v=>StoneCrucialsSMap[v] || v)
		Vue.filter('StoneLv', v=>StoneLvsSMap[v] || v)
		Vue.filter('StoneRoleType', v=>StoneRoleTypesSMap[v] || v)
		Vue.filter('StoneType', v=>StoneTypesSMap[v] || v)
		Vue.filter('StoneStudyStage', v=>PrjtStoneStagesSMap[v] || v)
		Vue.filter('Stage', (v,factor)=>(factor ? SiteStoneStagesSMap:PrjtStoneStagesSMap)[v] || v)
		Vue.filter('StoneStage', (v,factor)=>((factor ? SiteStoneStagesSMap:PrjtStoneStagesSMap)[v] || v) + ((factor ? SiteStagesSMap:prjtStagesSMap)[v]))
		Vue.filter('StoneSiteStage', v=>SiteStoneStagesSMap[v] || v)
		Vue.filter('StoneSuitState', v=>StoneSuitStatesSMap[v] || v)
		Vue.filter('LawState', v=>LawStatesSMap[v] || v)
		Vue.filter('LawType', v=>LawTypesSMap[v] || v)
		Vue.filter('OrgRegState', v=>OrgRegStatesSMap[v] || v)
		Vue.filter('Prodouct', v=>ProdouctsSMap[v] || v)
		Vue.filter('DocType', v=>DocTypesSMap[v] || v)
		Vue.filter('DocRole', v=>DocRolesSMap[v] || v)
		Vue.filter('RemarkState', v=>RemarkStatesSMap[v] || v)
		Vue.filter('DocState', v=>DocStatesSMap[v] || v)
		Vue.filter('DocFeeState', v=>DocFeeStatesSMap[v] || v)
		Vue.filter('DocFileWay', v=>DocFileWaysSMap[v] || v)

		Object.assign(Vue.prototype, Options)
		Object.assign(Vue.prototype, consts)
	}
}
