<template>
  <h2>{{$route.path}}出错了哦！</h2>
</template>

<script>
export default {
  name: "deving",
  created(){console.log("deving")},
};
</script>

