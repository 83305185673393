

export default {
    name: "personalPage",


    data() {
        return {

        };
    },

    created() {

    },

    methods: {

    }
}
;
